import React from 'react';
import PropTypes from 'prop-types';

const UploadExampleImg = ({ widthPerc, imgSrc, imgTitle, explanationText }) => {
	return (
		<>
			<p className='body'>{explanationText}</p>
			<br />
			<img
				src={imgSrc}
				alt={imgTitle || 'Example image'}
				style={{
					width: widthPerc ? `${widthPerc}%` : '50%',
					height: 'auto',
					borderRadius: '5px',
					border: '2px solid var(--brand-color)',
					margin: 'auto',
					display: 'block',
				}}
			/>
		</>
	);
};

UploadExampleImg.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	widthPerc: PropTypes.number,
	imgSrc: PropTypes.string.isRequired,
	imgTitle: PropTypes.string,
	explanationText: PropTypes.string.isRequired,
};
UploadExampleImg.defaultProps = {
	widthPerc: null,
	imgTitle: null,
};

export default UploadExampleImg;
