import React from 'react';
// import * as Yup from 'yup';
import UploadExampleImg from './UploadExampleImg';

// Locked registration fields
export const LOCKED_REGISTRATION_FIELDS = new Set([
	'registrationState',
	'registrationAddress.state',
	'firstName',
	'middleName',
	'lastName',
	'dob',
	'dlNumber',
	'dlExpDate',
	'dlState',
]);

export const PURCHASED_VEHICLE_SUBSTRING = 'purchasedVehicle.registrationDetails';
export const CURRENT_VEHICLE_SUBSTRING = 'currentVehicle.registrationDetails';
export const CONSTS_STRING = 'consts';

// T&R CONSTANTS
export const REGISTRANT_OPTIONS = {
	ONE_PERSON: 'onePerson',
};

export const SUPPORTED_PLATE_TYPES = {
	STANDARD: 'Standard',
	// DISABLED: 'Disabled',
	// SPECIALITY: 'Specialty',
	// RIDESHARE: 'Rideshare',
	// PERSONALIZED: 'Personalized',
};
export const SUPPORTED_USE_TYPES = {
	PERSONAL: 'Personal',
	COMMERCIAL: 'Commercial',
	// FORHIRE: 'ForHire',
	// OFFHIGHWAY: 'Offhighway',
};
export const VITU_PAYMENT_OPTIONS = {
	PAYINFULL: 'PayInFull',
	NATIVELOAN: 'NativeLoan',
	THIRDPARTYLOAN: 'ThirdPartyLoan',
};

export const OWNER_TYPES = {
	INDIVIDUAL: 'Individual',
	BUSINESS: 'Business',
};

// Information should be added to this object as we add more states
// Will dynamically populate buyer uploads pages with necessary info
export const STATE_ABBR_TO_FILE_UPLOAD_DATA = {
	CA: {
		UPLOAD_UnincorporatedAddress: {
			title: 'Proof of Unincorporated Address',
			description: (
				<p>
					A screenshot printout from the California Department of Tax and Fees is required
					as proof that the owner lives in an unincorporated area from:{' '}
					<a href='https://maps.cdtfa.ca.gov/'>
						California Department of Tax and Fees Search
					</a>{' '}
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of Unincorporated Address'],
		},
	},
	KS: {},
	MS: {},
	IL: {
		UPLOAD_PROOF_OF_RESIDENCE: {
			title: 'Proof of Residence',
			description: (
				<>
					<p>
						One proof of residence document listing your name and registration address
						are required. Acceptable proof of residence documents include:
					</p>
					<ul>
						<li>
							Home utility bill (gas, electric, water, garbage, sewer, landline phone,
							TV, internet, ISTA) displaying Montana address. Bill MUST be current
							and/or dated within the past 2 months.
						</li>
						<li>Bank Statement</li>
						<li>Paycheck or paystub</li>
						<li>
							The original copy of a rental agreement or rent payment receipt signed
							by the landlord or rental agent that includes the applicant's name and
							residential address.
						</li>
					</ul>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of Residence Document'],
		},
	},
	AR: {
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A copy of your Business License or a printout from the Arkansas Secretary of
					State business search tool is required. Printouts can be obtained from:{' '}
					<a href='https://www.ark.org/corp-search/index.php'>Business Search</a>{' '}
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business License or Printout'],
		},
		UPLOAD_LETTER_OF_AUTHORIZATION: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						A letter of authorization on company letterhead for the signer to sign on
						behalf of the company must be included.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of a authorization for signer document:'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_TAX_ASSESSMENT: {
			title: 'Copy of Verified Property Tax Assessment',
			description: (
				<>
					<p className='body'>
						A copy of the verified Property Tax Assessment listing your vehicle's VIN
						from your County Treasurer's office is required.
					</p>
					<p className='body'>
						You can obtain an assessment from your County Assessor's office in-person,
						over the phone, or online (in most counties). Contact your County Assessor's
						office for more information.
					</p>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Verified Tax Assessment'],
		},
	},
	TX: {
		UPLOAD_LETTER_OF_AUTHORIZATION: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						A company business card belonging to the signer or a letter of authorization
						on company letterhead for the signer to sign on behalf of the company must
						be included.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of a authorization for signer document:'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A copy of your Business License or a printout from the Texas Secretary of State
					business search tool is required. Printouts can be obtained from:{' '}
					<a href='https://mycpa.cpa.state.tx.us/coa/'>Business Search</a>{' '}
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business License or Printout'],
		},
	},
	MN: {
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A business filing from the Minnesota Secretary of State business search tool is
					required. Printouts can be obtained from:{' '}
					<a href='https://mblsportal.sos.state.mn.us/Business/Search'>Business Search</a>{' '}
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business Filing'],
		},
	},
	CO: {
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A copy of your Business License or a printout from the Colorado Secretary of
					State business search tool is required. Printouts can be obtained from:{' '}
					<a href='https://www.sos.state.co.us/biz/BusinessEntityCriteriaExt.do'>
						Business Search
					</a>{' '}
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business License or Printout'],
		},
		UPLOAD_LETTER_OF_AUTHORIZATION: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						An authorization for the signer to sign on behalf of the company must be
						included on company letterhead.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of an authorization document::'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
	},
	MT: {
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<>
					<p>
						A screenshot or printout from the Montana Secretary of State business search
						tool is required. Printouts can be obtained from:{' '}
						<a href='https://biz.sosmt.gov/search/business'>Business Search</a>{' '}
					</p>
					<p>The address listed on the printout must match the registration address.</p>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Secretary of State Printout'],
		},
		UPLOAD_PROOF_OF_RESIDENCE: {
			title: 'Proof of Residence',
			description: (
				<>
					<p>
						Two proof of residence documents listing your name and registration address
						are required. Acceptable proof of residence documents include:
					</p>
					<ul>
						<li>
							Home utility bill (gas, electric, water, garbage, sewer, landline phone,
							TV, internet, ISTA) displaying Montana address. Bill MUST be current
							and/or dated within the past 2 months.
						</li>
						<li>Bank Statement</li>
						<li>Paycheck or paystub</li>
						<li>Homeowners (not renters) insurance policy</li>
						<li>
							A current automobile or life insurance policy or bill dated within 2
							months
						</li>
						<li>
							The original copy of a rental agreement or rent payment receipt signed
							by the landlord or rental agent that includes the applicant's name and
							residential address.
						</li>
						<li>A Montana voter registration card</li>
					</ul>
				</>
			),
			minUploads: 2,
			uploadBlockTitles: ['Proof of Residence Document 1', 'Proof of Residence Document 2'],
		},
	},
	NM: {
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A printout from the New Mexico Secretary of State business search tool is
					required. Printouts can be obtained from:{' '}
					<a href='https://portal.sos.state.nm.us/BFS/online/corporationbusinesssearch'>
						Business Search
					</a>{' '}
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business License or Printout'],
		},
		UPLOAD_LETTER_OF_AUTHORIZATION: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						An authorization for the signer to sign on behalf of the company must be
						included on company letterhead. The individual signing the authorization
						must be listed as a registered agent or CEO of the company.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of an authorization document::'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_PROOF_OF_RESIDENCE: {
			title: 'Proof of Residence',
			description: (
				<>
					<p>
						Two proof of residence documents are required. Acceptable proof of residence
						documents include:
					</p>
					<ul>
						<li>
							A gas, electric, water, or phone bill that shows your current address.
						</li>
						<li>A rental property or purchase agreement.</li>
						<li>An auto, home, health insurance agreement or bill.</li>
						<li>A paycheck stub.</li>
						<li>A property tax statement or mortgage documents.</li>
						<li>
							A letter or document from a tribal, city, county, or state association
							in New Mexico.
						</li>
					</ul>
				</>
			),
			minUploads: 2,
			uploadBlockTitles: ['Proof of Residence Document 1', 'Proof of Residence Document 2'],
		},
	},
	OR: {
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A copy of a business filing from the Oregon Secretary of State business search
					tool
					<a href='https://sos.oregon.gov/business/pages/find.aspx'>
						Secretary of State Business Search
					</a>
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business Search'],
		},
		UPLOAD_PROOF_OF_RESIDENCE: {
			title: 'Proof of Residence',
			description: (
				<>
					<p>
						Two acceptable proof of residence documents required for Oregon residents:
					</p>
					<ul>
						<li>
							A property tax record, utility bills, rent receipts, a lease or rental
							agreement or other document that shows you reside in Oregon;
						</li>
						<li>
							Enrollment records or other documentation that you are attending an
							educational institution maintained by public funds and pay resident
							tuition fees;
						</li>
						<li>
							Motel, hotel, campground or recreational vehicle park receipts showing
							that you currently reside in Oregon and have remained in Oregon for six
							consecutive months or more;
						</li>
						<li>
							A statement dated within the last 60 days from a relief agency or
							shelter that you have no actual residence, but currently receive
							assistance in Oregon;
						</li>
						<li>
							Fuel receipts, motel receipts, or other documents showing you have lived
							in Oregon for at least six of the last twelve months.
						</li>
						<li>
							Documents showing you have a current account at a bank or credit union
							in Oregon and the account has been open for 60 days or more;
						</li>
						<li>
							A document showing receipt of public assistance from an agency of the
							State of Oregon dated within the last twelve months; or
						</li>
						<li>An Oregon voter registration card</li>
					</ul>
					<p>OR one or more from this list if domiciled in Oregon:</p>
					<ul>
						<li>
							A true copy of your Oregon permanent or part-year income tax return
							filed with the Oregon Department of Revenue for the previous tax year.
							If you filed as a part-year resident, the income tax return must show
							that you resided in Oregon at the end of that tax year.
						</li>
						<li>
							Proof that you have continuously maintained an Oregon residence while
							absent from the state;
						</li>
						<li>Proof that you own a residence in Oregon;</li>
						<li>
							Proof that you are temporarily residing outside of Oregon for a period
							of limited duration (paying non-resident tuition at an out of state
							school; temporary transfer of employment; temporary care of a family
							member out of state) and you have maintained ties to Oregon such as a
							bank account or voter registration;
						</li>
						<li>
							Proof that you are a member of the United States Armed Forces, or the
							spouse or domestic partner, or dependent child residing with a member,
							and Oregon is listed as your home in military records. A copy of your
							military LES (Leave and Earnings Statement) is acceptable proof.
						</li>
					</ul>{' '}
				</>
			),
			minUploads: 2,
			uploadBlockTitles: ['Proof of Residence Document 1', 'Proof of Residence Document 2'],
		},
		UPLOAD_USE_TAX_PAID: {
			title: 'Proof of Use Tax Paid',
			description: (
				<>
					<p>
						A copy of the VIN-specific vehicle use tax payment certificate containing an
						‘L number' in the bottom right corner must be provided.
					</p>
					<p>
						{' '}
						Use tax payment and certificate can be completed at
						https://revenueonline.dor.oregon.gov/tap/_/
					</p>
					<p>
						Instructions are available at
						https://www.oregon.gov/dor/forms/FormsPubs/publication-or-591-c_612-006.pdf
					</p>
				</>
			),
		},
	},
	AZ: {
		UPLOAD_MILITARY_ORDERS: {
			title: 'Military Documents',
			description: (
				<p className='body'>
					A copy of Current Military Orders or Leave and Earnings Statement is required.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Current Military Orders'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A copy of your Business License or a printout from the Arizona government
					business search tools is required. Printouts can be obtained from:{' '}
					<a href='https://apps.azsos.gov/apps/tntp/se.html'>
						Secretary of State Entity Search
					</a>{' '}
					or
					<a href='https://ecorp.azcc.gov/EntitySearch/Index'>
						{' '}
						Arizona Corporation Commission Entity Search
					</a>
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business License or Printout'],
		},
		UPLOAD_LETTER_OF_AUTHORIZATION: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						An authorization for the signer to sign on behalf of the company must be
						included on company letterhead.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of an authorization document::'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_PROOF_OF_RESIDENCE: {
			title: 'Proof of Residence',
			description: (
				<>
					<p>
						Two proof of residence documents are required. Acceptable proof of residence
						documents include:
					</p>
					<ul>
						<li>Utility bill</li>
						<li>Bank statement</li>
						<li>Insurance policy</li>
					</ul>
				</>
			),
			minUploads: 2,
			uploadBlockTitles: ['Proof of Residence Document 1', 'Proof of Residence Document 2'],
		},
	},
	MI: {
		UPLOAD_LETTER_OF_AUTHORIZATION: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						An authorization for the signer to sign on behalf of the company must be
						included on company letterhead.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of an authorization document::'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_PROOF_OF_RESIDENCE: {
			title: 'Proof of Residence',
			description: (
				<>
					<p>
						Two proof of residence documents are required. Acceptable proof of residence
						documents include:
					</p>
					<ul>
						<li>Utility bill or credit card bill issued within the last 90 days</li>
						<li>
							Account statement from a bank or other financial institution issued
							within the last 90 days
						</li>
						<li>
							Michigan high school, college or university report cards or transcripts
						</li>
						<li>
							Mortgage, lease or rental agreement (Lease and rental agreements must
							include landlord’s telephone number)
						</li>
						<li>
							Pay stub or earnings statement issued with the name and address of the
							employee
						</li>
						<li>Life, health, auto or home insurance policy</li>
						<li>
							Federal, state or local government documents, such as receipts, licenses
							or assessments
						</li>
						<li>
							Michigan title and registration (Registration must show current
							residential address)
						</li>
					</ul>
				</>
			),
			minUploads: 2,
			uploadBlockTitles: ['Proof of Residence Document 1', 'Proof of Residence Document 2'],
		},
	},
	MO: {
		UPLOAD_PROOF_OF_PAID_PERSONAL_PROPERTY_TAX: {
			title: 'either Proof of Paid Personal Property Tax OR a Statement of Non-Assessment',
			description: (
				<>
					<p>Please upload one of two documents:</p>
					<br />
					<p>
						<b>Personal Property Tax Receipt:</b>
						<ul>
							<li>
								{' '}
								If taxes were paid for the previous year, you must provide this.
							</li>
							<li>Copies can be submitted</li>
							<li>Must state 'Paid' and list the previous year</li>
							<li>Must state your name</li>
							<li>
								Must state the county and state where the vehicle is being
								registered
							</li>
						</ul>
						This is a print out form that can be obtained at your local county office.
						<UploadExampleImg
							imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/MO-Paid-Personal-Property-Tax-Receipt-Example'
							imgTitle='Paid Personal Property Tax Receipt Example'
							explanationText='Example of a paid personal property tax receipt:'
						/>
					</p>
					<p>
						<b>Statement of Non-Assessment:</b>
						<ul>
							<li>
								{' '}
								If no property tax was owed to the county where you're registering
								the vehicle in the previous year, you must provide this.{' '}
							</li>
							<li>Copies can be submitted</li>
							<li>Must be signed by the county clerk</li>
						</ul>
						<UploadExampleImg
							imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/MO-statement-of-non-assessment-example'
							imgTitle='Statement of Non-Assessment Example'
							explanationText='Example of a statement of non-assessment:'
						/>
					</p>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: [
				'Proof of Paid Personal Property Tax',
				'Statement of Non-Assessment',
			],
		},
	},
	FL: {
		UPLOAD_MILITARY_ORDERS: {
			title: 'Military Documents',
			description: <p className='body'>A copy of Current Military Orders is required.</p>,
			minUploads: 1,
			uploadBlockTitles: ['Current Military Orders'],
		},
		UPLOAD_ONE_AND_THE_SAME_AFFIDAVIT: {
			title: 'One-and-the-same Affidavit',
			description: (
				<>
					<p className='body'>
						The name used to register your vehicle is significantly different from that
						appearing on your license (or other supporting documents)
					</p>
					<p>
						Please upload a one-and-the-same affidavit indicating that the different
						names represent the same entity. This affidavit must be notarized.
					</p>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['One-and-the-same Affidavit'],
		},
		UPLOAD_AUTH_FOR_SIGNER: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						Must be on company letterhead and be signed by an Owner or Registered Agent
						of the company.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of an authorization document::'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_ID_FOR_BUSINESS: {
			title: 'Copy of an ID',
			description: (
				<>
					<p className='body'>
						Current proof of identification is required. One of the following forms of
						identification is acceptable:
					</p>
					<ul className='body'>
						<li>A valid Florida driver’s license</li>
						<li>An id card issued by Florida or another state</li>
						<li>A valid passport</li>
					</ul>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Front of ID', 'Back of ID (if applicable)'],
		},
		UPLOAD_PROOF_OF_INSURANCE: {
			title: 'Proof of Insurance',
			description: (
				<>
					<p className='body'>
						To register a vehicle, you must provide proof of Personal Injury Protection
						(PIP) and Property Damage Liability (PDL) automobile insurance. This must be
						issued by an insurance company licensed in Florida. A "Qualifying for a
						Self-Insurance" certificate issued by FLHSMV is also acceptable.{' '}
					</p>
					<p className='body'>
						Florida is a no-fault state and requires all vehicle owners to carry a
						minimum amount of vehicle insurance. Failure to do so can result in severe
						penalties that may include monetary fines and jail time. Minimum levels of
						financial responsibility are:
					</p>
					<ul>
						<li> $10,000 in PIP </li>
						<li> $10,000 in PDL </li>
					</ul>
					<p className='body'>
						The insurance policy <b> must </b> demonstrate coverage for the business and
						of the new vehicle. To show coverage of the new vehicle, it's important the
						VIN is included on the uploaded proof of insurance. Out-of-State insurance
						will not be accepted.
					</p>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of insurance'],
		},
		// regAndTitlingUploadId: {
		// 	title: 'A copy of an ID' ,
		// 	description: (
		// 		<>
		// 			<p className='body'>
		// 				Current proof of identification is required. One of the following forms of
		// 				identification is acceptable:
		// 			</p>
		// 			<ul className='body'>
		// 				<li>A valid Florida driver’s license</li>
		// 				<li>An id card issued by Florida or another state</li>
		// 				<li>A valid passport</li>
		// 			</ul>
		// 			<p className='body'>
		// 				A valid out-of-state Driver's License may be used by non-resident military
		// 				service members and their families deployed in Florida. If the form of
		// 				identification submitted <b> is expired </b>, delays in processing may
		// 				occur. Please ensure the driver’s license or provided identification is
		// 				up-to-date.
		// 			</p>
		// 		</>
		// 	),
		// 	minUploads: 2,
		// 	uploadBlockTitles: ['Front of ID', 'Back of ID'],
		// },
		// regAndTitlingUploadInsurance: {
		// 	title: 'proof of Florida insurance' ,
		// 	description: (
		// 		<>
		// 			<p className='body'>
		// 				To register a vehicle, you must provide proof of Personal Injury Protection
		// 				(PIP) and Property Damage Liability (PDL) automobile insurance. This must be
		// 				issued by:
		// 			</p>
		// 			<ul>
		// 				<li>An insurance company licensed in Florida, or</li>
		// 				<li>'Qualifying for a self-insurance' certificate issued by FLHSMV</li>
		// 			</ul>
		// 			<p className='body'>
		// 				Florida is a no-fault state and requires all vehicle owners to carry a
		// 				minimum amount of vehicle insurance. Failure to do so can result in severe
		// 				penalties that may include monetary fines and jail time. Minimum levels of
		// 				financial responsibility are:
		// 			</p>
		// 			<ul>
		// 				<li> $10,000 in PIP </li>
		// 				<li> $10,000 in PDL </li>
		// 			</ul>
		// 			<p className='body'>
		// 				The insurance policy <b> must </b> demonstrate coverage for you and of the
		// 				new vehicle. To show coverage of the new vehicle, it's important the VIN is
		// 				included on the uploaded proof of insurance. Out-of-State insurance will not
		// 				be accepted except for FL residents (including military personnel) who may
		// 				be stationed out on a temporary basis.
		// 			</p>
		// 		</>
		// 	),
		// 	minUploads: 1,
		// 	uploadBlockTitles: ['Front of your insurance card or insurance form'],
		// },
	},
	GA: {
		UPLOAD_BUSINESS_PRINTOUT: {
			title: 'Business Printout',
			description: (
				<p>
					A current printout showing that your business is in good standing must be
					provided. Printouts can be obtained from the Georgia Secretary of State's
					business search tool:{' '}
					<a href='https://ecorp.sos.ga.gov/BusinessSearch'> Business Search</a>
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business Printout'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A copy of the business license is required. The county listed on the business
					license must match that of the registration address.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business License'],
		},
		UPLOAD_PROOF_OF_RESIDENCE: {
			title: 'Proof of Residence',
			description: (
				<>
					<p>
						Two proof of residence documents are required. Acceptable proof of residence
						documents include:
					</p>
					<ul>
						<li>Utility bill</li>
						<li>Bank statement</li>
						<li>Lease agreement</li>
						<li>
							Letter from an employer on company letterhead with your name and current
							address
						</li>
					</ul>
				</>
			),
			minUploads: 2,
			uploadBlockTitles: ['Proof of Residence Document 1', 'Proof of Residence Document 2'],
		},
	},
	PA: {
		UPLOAD_AUTH_FOR_SIGNER: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						Must be on company letterhead and be signed by an Owner or Registered Agent
						of the company.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of a authorization for signer document:'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p className='body'>
					A Certificate of Organization / Business license or business entity screenshot
					from{' '}
					<a href='https://www.corporations.pa.gov/search/corpsearch'>
						https://www.corporations.pa.gov/search/corpsearch
					</a>{' '}
					are acceptable as proof.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of business'],
		},
	},
	NC: {
		UPLOAD_QUALIFICATION_FOR_OUT_OF_STATE_REG: {
			title: 'Proof of Eligibility for NC Registration',
			description: (
				<>
					<p className='body'>
						In order to register your vehicle in North Carolina using an out-of-state
						license, you'll need to upload one of the following documents:
					</p>
					<ul>
						<li>
							<b>Non-Resident Students:</b> A student ID showing current enrollment in
							a North Carolina college or university
						</li>
						<li>
							<b>Military Members:</b> Military Orders and LES
						</li>
					</ul>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Student ID', 'Military Orders/LES'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<>
					<p className='body'>
						The state of North Carolina requires businesses to provide documentation
						proving the business exists and is in good standing in order to title and
						register a vehicle. Note: the business filing date must be before the
						vehicle's purchase date. The following documents are accepted:
					</p>
					<ul>
						<li>Secretary of State Business Search Printout</li>
						<li>
							{' '}
							Copy of the firm, partnership, corporation, or business incorporation
							papers
						</li>
					</ul>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of business'],
		},
	},
	NY: {
		UPLOAD_ID_OUTOFSTATE: {
			title: 'Proof of Identification Documents',
			description: (
				<p className='body'>
					Another proof of identification is required. Note: we already have your non-NY
					driver's license. New York needs two more points of identification (as defined{' '}
					<a
						href='https://dmv.ny.gov/forms/id82.pdf'
						target='_blank'
						rel='noopener noreferrer'>
						{' '}
						here{' '}
					</a>{' '}
					) in order to verify your identity. Please upload some additional documentation
					below to yield at least 2 additional points to satisfy the linked NY DMV
					document.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: [
				'Proof of ID 1',
				'Proof of ID 2',
				'Proof of ID 3',
				'Proof of ID 4',
				'Proof of ID 5',
			],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<>
					<p className='body'>
						When registering a vehicle under a business, proof of business validity must
						be submitted. The following forms of documentation will satisfy the proof of
						business requirement:
					</p>
					<ul>
						<li> Business License </li>
						<li> Certificate of Incorporation </li>
						<li> Online filing receipt </li>
						<li> Prior title/registration papyerwork in the name of the business </li>
						<li> Business entity search document from NY website </li>
					</ul>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of business'],
		},
		UPLOAD_AUTH_FOR_SIGNER: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						Must be on company letterhead and be signed by an Owner or Registered Agent
						of the company.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of a authorization for signer document:'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
	},
	TN: {
		UPLOAD_LETTER_OF_AUTHORIZATION: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						An authorization for the signer to sign on behalf of the company must be
						included on company letterhead.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of an authorization document::'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A copy of your Business License or Filing Acknowledgement document from the
					Tennessee Secretary of State is required and must display the same address that
					vehicle is being registered to. Filing Acknowledgement can be obtained from the
					Tennessee Secretary of State Business Search tool:{' '}
					<a href='https://tnbear.tn.gov/Ecommerce/FilingSearch.aspx'>Business Search</a>
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business License'],
		},
		UPLOAD_MILITARY_DOCS: {
			title: 'Current Military Orders',
			description: (
				<p className='body'>
					A copy of Current Military Orders and a valid copy of a Leave Earnings Satement
					is required.
				</p>
			),
			minUploads: 2,
			uploadBlockTitles: ['Current Military Orders', 'Leave Earnings Statement'],
		},
		UPLOAD_MILITARY_ORDERS: {
			title: 'Current Military Orders',
			description: <p className='body'>A copy of Current Military Orders is required.</p>,
			uploadBlockTitles: ['Current Military Orders'],
		},
		UPLOAD_ONE_AND_THE_SAME_AFFIDAVIT: {
			title: 'One-and-the-same Affidavit',
			description: (
				<>
					<p className='body'>
						The name used to register your vehicle is significantly different from that
						appearing on your license (or other supporting documents){' '}
					</p>
					<p>
						Please upload a one-and-the-same affidavit indicating that the different
						names represent the same entity. This affidavit must be notarized.
					</p>
				</>
			),
			uploadBlockTitles: ['One-and-the-same Affidavit'],
		},
		regAndTitlingUploadInsurance: {
			title: 'proof of Florida insurance',
			description: (
				<>
					<p className='body'>
						You are required to upload 3 acceptable proof of residence documents (must
						include residence address and name of applicant or applicant’s spouse). See
						below for examples of acceptable proof of residence documents:
					</p>
					<ul className='body'>
						<li>A valid Tennessee driver’s license</li>
						<li>An id card issued by Tennessee or another state</li>
						<li>A valid passport</li>
						<li>Utility bill</li>
						<li>Bank Statement</li>
						<li>
							Current rental/Mortgage contract or receipt including deed of sale for
							property
						</li>
						<li>
							Current employer verification of residence address or letter from
							employer as long as it is on company letterhead with original signature.
							If the employer does not have a letterhead then the signature of the
							employer must be notarized
						</li>
						<li>
							Current paycheck/check, stub, work ID or badge, if address is included.
						</li>
						<li>
							Current automobile, life or health insurance policy (Wallet Cards are
							not acceptable)
						</li>
						<li>
							Current driver license/ID issued by the Tennessee Department of Safety
							to a parent, legal guardian or spouse of applicant.
						</li>
						<li> Current Tennessee motor vehicle registration or title </li>
						<li> Current Tennessee voter registration </li>
						<li> Current W-2 form within last 12 months </li>
						<li>
							Receipt for personal property or real estate taxes paid within past year
						</li>
						<li>
							In case of a student enrolled in a public or private school in this
							state, the student may provide a photo student ID and acceptable
							documentation from the Deano or Bursar Office that the student lives on
							campus.
						</li>
						<li> Or one of the following documents. </li>
						<li>Individual Taxpayer Identification Number (ITIN) issued by the IRS </li>
						<li>
							Form I-94 issued by the applicant by the US Citizenship and immigration
							Service
						</li>
					</ul>
				</>
			),
			minUploads: 2,
			uploadBlockTitles: [
				'Proof of Residence Document 1',
				'Proof of Residence Document 2',
				'Proof of Residence Document 3',
			],
		},
	},
	OH: {
		UPLOAD_PROOF_OF_RESIDENCY: {
			title: 'Proof of Residency For Out of State Buyer',
			description: (
				<>
					<p className='body'>
						You are required to upload 2 different forms of proof of residency, and a
						copy of your social security number or A copy of your W2 showing the SSN.
						Acceptable proof of residency documents (must show residence address and
						your name) include the following:
					</p>
					<ul className='body'>
						<li>A valid driver’s license</li>
						<li>A government id card</li>
						<li>A valid passport</li>
						<li>Utility bill</li>
						<li>Bank Statement</li>
						<li>
							Current rental/Mortgage contract or receipt including deed of sale for
							property
						</li>
						<li>
							Current employer verification of residence address or letter from
							employer as long as it is on company letterhead with original signature.
							If the employer does not have a letterhead then the signature of the
							employer must be notarized
						</li>
						<li>
							Current paycheck/check, stub, work ID or badge, if address is included.
						</li>
						<li>
							Current automobile, life or health insurance policy (Wallet Cards are
							not acceptable)
						</li>
						<li> Current motor vehicle registration or title </li>
						<li> Current voter registration </li>
						<li> Current W-2 form within last 12 months </li>
						<li>
							Receipt for personal property or real estate taxes paid within past year
						</li>
						<li>
							In case of a student enrolled in a public or private school in this
							state, the student may provide a photo student ID and acceptable
							documentation from the Dean or Bursar Office that the student lives on
							campus.
						</li>
						<li> Or one of the following documents. </li>
						<li>Individual Taxpayer Identification Number (ITIN) issued by the IRS</li>
						<li>
							Form I-94 issued by the applicant by the US Citizenship and immigration
							Service
						</li>
					</ul>
				</>
			),
			minUploads: 3,
			uploadBlockTitles: [
				'Proof of Residency document 1',
				'Proof of Residency document 2',
				'Copy of social security number OR copy of your W2 showing your SSN',
			],
		},
		UPLOAD_ProofOfEligibility: {
			title: 'Acceptable Proof of Eligibility',
			description: (
				<>
					<p className='body'>
						Additional documents are required in order to meet eligibility requirements
						for certain license plates, placards, and military tax exemptions.
						Acceptable forms of eligibility documentation are listed below:
					</p>
					<p className='body'>
						<strong>Placards:</strong>
					</p>
					<ul className='body'>
						<li>Disabled Placard: A prescription for the placard from a physician.</li>
						<li>
							Disabled Veteran Placard: A letter from VA within the last year
							indicating owner’s service related disability.
						</li>
						<li>
							Active Duty Disabled Placard: A current convalescent leave statement
							from the Department of Defense.
						</li>
					</ul>
					<p className='body'>
						<strong>Military exemptions:</strong>
					</p>
					<ul className='body'>
						<li>A copy of the service member’s current military orders and LES.</li>
					</ul>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: [
				'Proof of eligibility document 1',
				'Proof of eligibility document 2',
				'Proof of eligibility document 3',
			],
		},
		UPLOAD_MilitaryOrders: {
			title: 'Military Orders',
			description: (
				<>
					<p className='body'>
						To qualify for sales and use tax exemption, military members must provide a
						copy of their current official military orders and LES.
					</p>
					<p className='body'>
						<strong>Important:</strong>
					</p>
					<ul className='body'>
						<li>
							Must be an Ohio resident and stationed in another state for more than
							six months.
						</li>
					</ul>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/oems/bp-test-oem-id/assets/oh_military_orders'
						imgTitle='Military Orders Example'
						explanationText='Example of a military orders document:'
					/>
					<br />
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Military Orders'],
		},
	},
	SC: {
		// UPLOAD_PROOF_OF_PERSONAL_PROPERTY_TAX_PAID: { ---------- BUYER NO LONGER NEEDS TO UPLOAD, WE HANDLE
		// 	title: 'Receipt for Paid Personal Property Tax',
		// 	description: (
		// 		<>
		// 			<p className='body'>
		// 				South Carolina requires verification of paid property tax to register a
		// 				vehicle. The owner must call or visit their local county tax office to pay
		// 				and obtain the receipt.
		// 			</p>
		// 			<p>
		// 				<b> Note: you will need a bill of sale to pay this tax. </b>
		// 				Click to button below to download a Bill of Sale. You'll have to countersign
		// 				the document. <b> Only use this bill of sale for paying this tax </b> - we
		// 				will generate a new one with an updated date later in the process.
		// 			</p>
		// 		</>
		// 	),
		// 	minUploads: 1,
		// 	uploadBlockTitles: ['Receipt for Paid Personal Property Tax'],
		// },
		UPLOAD_PROOF_OF_OUT_OF_STATE_REG_QUAL: {
			title: 'Proof of Eligibility for Out of State Registration',
			description: (
				<>
					<p className='body'>
						If you are applying with an out-of-state license, you must meet one of the
						following criteria:
					</p>
					<ul>
						<li>
							{' '}
							The vehicle owner is a permanent resident of another state and is on
							active military duty and is stationed in South Carolina
						</li>
						<li>
							{' '}
							The vehicle owner is a permanent resident of another state but is
							currently enrolled in a school in South Carolina
						</li>
						<li>
							{' '}
							The vehicle owner is a permanent resident of another state but the owner
							or co-owner intends to principally garage* the vehicle in this state
						</li>
						<li>
							{' '}
							The vehicle owner is otherwise capable of attaining a driver’s license,
							beginner’s permit or special identification card from South Carolina,
							except for a medical or physical condition that can be documented and
							verified by the Department and the owner intends to principally garage
							the vehicle in this State and the vehicle will be driven by a driver who
							is not the owner.{' '}
						</li>
					</ul>
					<p className='body'>
						<b>*Principally garaged</b> means the vehicle is garaged for six or more
						months of the year on property in the state. For full information on all
						accepted forms used to satisfy this requirement, look at the Instruction
						Sheet on page two of{' '}
						<a href='https://www.scdmvonline.com/-/media/Forms/TI-006.ashx'>
							{' '}
							the linked PDF.
						</a>
					</p>
					<h3 className='body'>Acceptable Forms of Proof</h3>
					<br />
					<p className='body'>
						<ul>
							<li>
								<strong>Active Military Duty:</strong> The vehicle owner is on
								active duty military service in SC. Please upload your military
								identification card and ONE of the following:
								<ul>
									<li> orders indicating duties in SC, or </li>
									<li>
										{' '}
										Leave and Earnings Statement (LES) showing deployment in SC{' '}
									</li>
								</ul>
							</li>
							<br />
							<li>
								<strong>School Enrollment:</strong> Submit school identification
								card or enrollment forms not more than 12 months old
							</li>
							<br />
							<li>
								<strong> Vehicle Principally Garaged in South Carolina:</strong>{' '}
								Please submit one of the following:
								<ul>
									<li>
										{' '}
										If the vehicle owner is a homeowner or is leasing a
										residence in the state, A copy of the deed, mortgage or a
										current (not more than 90 days old) utility bill in the
										homeowner’s name.{' '}
									</li>
									<li>
										{' '}
										If the vehicle owner is employed in South Carolina, a
										statement from the employer indicating employment.{' '}
									</li>
									<li>
										{' '}
										If the vehicle owner resides with a homeowner or an
										individual leasing a residence in the state, a statement
										from the homeowner attesting to the fact that the applicant
										resides in his home; and A copy of the homeowner’s South
										Carolina credential or an item from (a) above. SC resident
										with whom vehicle owner resides must also complete form{' '}
										<a href='https://www.scdmvonline.com/-/media/Forms/TI-006.ashx'>
											{' '}
											TI-006A Affidavit of Vehicle Housed at SC Residence{' '}
										</a>
										.{' '}
									</li>
								</ul>
							</li>
						</ul>
					</p>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: [
				'Active military duty: Military Identification Card',
				'Active military duty: Orders duties or Leave and Earnings Statement (LES)',
				'School Enrollment: School ID card or enrollment forms',
				'Vehicle Garaged in South Carolina: Statement from home owner',
				'Vehicle Garaged in South Carolina: Homeowner copy of SC credential',
				'Vehicle Garaged in South Carolina: Completed TI-006A Form',
			],
		},
		UPLOAD_PROOF_OF_RESIDENCE: {
			title: 'Proof of Residence',
			description: (
				<>
					<p className='body'>
						If the owner does not have a SC license, they will need to provide a valid
						out-of-state license and one proof of residency dated within 60 days of the
						purchase. Acceptable proof of residency documents include:
					</p>
					<ul>
						<li> Utility Bill</li>
						<li> Bank Statement</li>
						<li> Insurance Policy</li>
						<li> Personal property tax receipt</li>
					</ul>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of Residence'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<>
					<p className='body'>
						A copy of the South Carolina Secretary of State filing page is required to
						title and register a vehicle under a business. The Filing page must list the
						owner, the company as 'Active', and the status as 'Good Standing'. To access
						the South Carolina Secretary of State filing page, please visit{' '}
						<a href='https://businessfilings.sc.gov/BusinessFiling/Entity/Search'>
							https://businessfilings.sc.gov/BusinessFiling/Entity/Search
						</a>
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/proof-of-business-example-nc.PNG'
						imgTitle='Proof of business example'
						explanationText='Example of a proof of business document:'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of business'],
		},
	},
	VA: {
		UPLOAD_PROOF_OF_RESIDENCY_AND_SS_CARD: {
			title: 'Proof of Residency and Social Security Card',
			description: (
				<>
					<p className='body'>
						Since you're applying with an out-of-state driver's license, you need to
						provide proof of residency in Virginia and a front and back copy of your
						social security card. <br /> <br /> <b> Note: </b> the name and address on
						the proof of residency document must match the registration name and address
						(no P.O box nor business addresses accepted)!
					</p>
					<p className='body'>
						Acceptable proof of residency documents include:
						<ul>
							<li>
								Payroll check stub issued by an employer within the last two months
							</li>
							<li>W-2 form or 1099 form (not more than 18 months old)</li>
							<li>Original monthly bank statement not more than two months old</li>
							<li>
								Utility bill, not more than two months old, issued to the applicant
							</li>
							<li>
								Current automobile or life insurance bill (cards or policies are not
								accepted)
							</li>
							<li>
								Current certified copy of school records/transcript or official
								report card
							</li>
							<li>
								Driver’s license, learner’s permit or DMV-issued photo ID cards
								displaying the applicant’s current address
							</li>
							<li>Current homeowners insurance policy or bill</li>
							<li>
								Canceled check (not more than two months old) with both name and
								address imprinted
							</li>
						</ul>
					</p>
				</>
			),
			minUploads: 3,
			uploadBlockTitles: [
				'Proof of Residency',
				'Social Security Card (front)',
				'Social Security Card (back)',
			],
		},
	},
	WA: {
		UPLOAD_PROOF_OF_RESIDENCY: {
			title: 'Proof of Residency',
			description: (
				<p className='body'>
					Acceptable proof of residency documents include:
					<ul>
						<li>
							Home utility bill displaying Washington address (gas, electric, water,
							garbage, sewer, landline phone) dated within the past 2 months{' '}
							<b> Not acceptable: cable, internet, or TV bills </b>
						</li>
						<li>Bank Statement</li>
						<li>Home owners (not renters) insurance policy</li>
						<li>Auto insurance policy or bill dated within 2 months</li>
					</ul>
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of Residency'],
		},
		UPLOAD_MILITARY_ORDERS: {
			title: 'Current Military Orders',
			description: (
				<p className='body'>
					Military orders should show <b>current station assignment</b> and{' '}
					<b>home of record</b>.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of Residency'],
		},
		UPLOAD_AUTH_FOR_SIGNER: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						Must be on company letterhead and be signed by an Owner or Registered Agent
						of the company.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of a authorization for signer document:'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p className='body'>
					A print out of the SOS Business Listing is required from the{' '}
					<a href='https://www.sos.wa.gov/corps/'>
						{' '}
						Washington Secretary of State website
					</a>
					. Please note that the FEIN provided must match the Business Name on file with
					the state.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of business'],
		},
	},
	NJ: {
		UPLOAD_ID_OUTOFSTATE: {
			title: 'Proof of Identification Documents',
			description: (
				<p className='body'>
					Another proof of identification is required. Note: we already have your non-NJ
					driver's license. New Jersey needs two more points of identification (as defined{' '}
					<a
						href='https://www.nj.gov/mvc/pdf/license/Standard_License_Sheet_Engl.pdf'
						target='_blank'
						rel='noopener noreferrer'>
						{' '}
						here{' '}
					</a>{' '}
					) in order to verify your identity. Please upload additional documentation below
					to yield: at least 2 additional points, proof of residency, as well as proof of
					SSN or proof of ITIN to satisfy the linked NJ DMV document.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: [
				'Proof of ID 1',
				'Proof of ID 2',
				'Proof of ID 3',
				'Proof of ID 4',
				'Proof of ID 5',
			],
		},
	},
	IN: {
		UPLOAD_PROOF_OF_RESIDENCY: {
			title: 'Proof of Residency',
			description: (
				<p className='body'>
					Acceptable proof of residency documents include:
					<ul>
						<li>
							Home utility bill displaying Washington address (gas, electric, water,
							garbage, sewer, landline phone) dated within the past 2 months{' '}
							<b> Not acceptable: cable, internet, or TV bills </b>
						</li>
						<li>Bank Statement</li>
						<li>Home owners (not renters) insurance policy</li>
						<li>Auto insurance policy or bill dated within 2 months</li>
					</ul>
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of Residency'],
		},
	},
	NE: {
		UPLOAD_LETTER_OF_AUTHORIZATION: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						A letter of authorization on company letterhead for the signer to sign on
						behalf of the company must be included.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of a authorization for signer document:'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_MILITARY_ORDERS: {
			title: 'Military Documents',
			description: (
				<p className='body'>
					A copy of Current Military Orders or Leave and Earnings Statement is required.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Current Military Orders'],
		},
	},
	VT: {
		UPLOAD_LETTER_OF_AUTHORIZATION: {
			title: 'Authorization for Signer',
			description: (
				<>
					<p className='body'>
						A letter of authorization on company letterhead for the signer to sign on
						behalf of the company must be included.
					</p>
					<UploadExampleImg
						imgSrc='https://storage.googleapis.com/airdealer-67669.appspot.com/assets/AuthforSigner.jpg'
						imgTitle='Authorization For Signer Example'
						explanationText='Example of a authorization for signer document:'
					/>
				</>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization for Signer'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A copy of your Business License or a printout from the Arkansas Secretary of
					State business search tool is required. Printouts can be obtained from:{' '}
					<a href='https://www.ark.org/corp-search/index.php'>Business Search</a>{' '}
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business License or Printout'],
		},
		UPLOAD_PROOF_OF_RESIDENCY: {
			title: 'Proof of Residency',
			description: (
				<p className='body'>
					Acceptable proof of residency documents include:
					<ul>
						<li>
							Home utility bill displaying Vermont address (gas, electric, water,
							garbage, sewer, landline phone) dated within the past 2 months{' '}
							<b> Not acceptable: cable, internet, or TV bills </b>
						</li>
						<li>Bank Statement</li>
						<li>Home owners (not renters) insurance policy</li>
						<li>Auto insurance policy or bill dated within 2 months</li>
					</ul>
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of Residency'],
		},
	},
	SD: {
		UPLOAD_SSN: {
			title: 'Proof of SSN',
			description: (
				<p className='body'>
					Since you're applying with an out-of-state driver's license, you need to provide
					proof of Social Security. Acceptable documents include a copy of social security
					card, W2 Form, or a 1099.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of SSN'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A copy of your Business License or a printout from the Arkansas Secretary of
					State business search tool is required. Printouts can be obtained from:{' '}
					<a href='https://www.ark.org/corp-search/index.php'>Business Search</a>{' '}
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business License or Printout'],
		},
	},
	ME: {
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A printout of the business entity search or a copy of the business license may
					be provided. The agent signing on behalf of the company must be listed on the
					business license or SOS printout. Otherwise, a letter of authorization will be
					required. Refer to the attached document for guidance.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business License or Printout'],
		},
		UPLOAD_PROOF_OF_RESIDENCE: {
			title: 'Proof of Residence',
			description: (
				<p>
					If owner(s) driver's license is not ME issued or address does not match, proof
					of residence is required. Valid Forms include: Maine Vehicle Registration,
					Utility Bill, Maine Resident Hunting or Fishing License, Mortgage Agreement,
					Residential Lease, Insurance Policy or ID card, SR22, Tax Return, Paycheck Stub,
					or W-2
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Residence Proof Document'],
		},
		UPLOAD_BUSINESS_ID: {
			title: 'Business Identification',
			description: (
				<p>
					Required from the person signing on behalf of the business. Out of State
					Identification is acceptable without proof of residency.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Business ID Document'],
		},
		UPLOAD_LETTER_OF_AUTHORIZATION: {
			title: 'Letter of Authorization',
			description: (
				<p>
					If the person signing on behalf of the company is not listed as an officer on
					the corporate documents, a signed letter of authorization on company letterhead
					stating that the person signing is an authorized agent of the company and can
					sign as such must be provided.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Authorization Letter'],
		},
	},
	DC: {
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					A copy of the DC business license must be provided along with the FEIN. The
					address on the paperwork must match the address on the business license. If the
					business license has “E-HOP” on it, a Home Occupancy Permit is required.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['DC Business License and FEIN'],
		},
		UPLOAD_CERTIFICATE_OF_OCCUPANCY: {
			title: 'Certificate of Occupancy',
			description: (
				<p>
					A lease agreement is required if the buyer's name is not listed on the DC
					Certificate of Occupancy.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['DC Certificate of Occupancy and Lease (if applicable)'],
		},
		UPLOAD_DC_VEHICLE_INSPECTION_REPORT: {
			title: 'DC Vehicle Inspection Report',
			description: <p>Out of state inspections are NOT accepted.</p>,
			minUploads: 1,
			uploadBlockTitles: ['DC Vehicle Inspection Report'],
		},
	},
	NH: {
		UPLOAD_PROOF_OF_RESIDENCE: {
			title: 'Proof of New Hampshire Residence',
			description: (
				<p>
					Owner must provide one proof of residence document when registering a vehicle.
					Documents must be dated within 45 days of applying for registration and match
					the name and address on the transaction documents. Acceptable, valid forms
					include:
					<ul>
						<li>NH vehicle registration certificate</li>
						<li>Building lease, rental agreement, or deed with property address</li>
						<li>Paycheck, paystub, or employment contract</li>
						<li>Current utility bill with service address shown</li>
					</ul>
					<strong>IMPORTANT INFORMATION</strong>
					<ul>
						<li>
							For Military members who do not have a permanent NH residence, a current
							utility bill of the individual they reside with must be provided along
							with a copy of that individual’s Driver’s License.
						</li>
						<li>Insurance Documents ARE NOT accepted as proof of residence.</li>
					</ul>
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of Residence Document (NH)'],
		},
	},
	RI: {
		UPLOAD_LEASING_AGREEMENT: {
			title: 'Copy of Leasing Agreement',
			description: <p>Must include all pages.</p>,
			minUploads: 1,
			uploadBlockTitles: ['Leasing Agreement (All Pages)'],
		},
		UPLOAD_TAR_CERTIFICATE: {
			title: 'Trade-In Vehicle: Copy of Certificate of Title or Registration',
			description: (
				<p>
					A copy of the RI Certificate of Title or RI Registration Card in the owner's
					name is required to receive trade-in credit. The owner's name on the document
					must match the owner's name on the application. For passenger vehicles & travel
					trailers, the trade must be of the same vehicle type; transactions involving
					trucks will not receive any trade credit.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Certificate of Title or Registration (Trade-In Vehicle)'],
		},
		UPLOAD_PROOF_OF_RESIDENCY: {
			title: 'Proof of Rhode Island Residency',
			description: (
				<p>
					Must be submitted with an out-of-state ID. Acceptable documents within valid
					effective dates include:
					<ul>
						<li>
							Homeowner/Renter Insurance Policy (must be current, in the owner's name,
							and reflect a Rhode Island address)
						</li>
						<li>
							Auto Insurance Policy (must be current, in the owner's name, and reflect
							a Rhode Island address)
						</li>
						<li>Property Tax Bill for Residence</li>
						<li>Valid Voter Registration Card</li>
					</ul>
					Valid within 60 days:
					<ul>
						<li>
							Utility Bill (must be in the name of the owner or immediate family
							member and reflect a Rhode Island address)
						</li>
						<li>
							Bank Statement (must be in the name of the owner or immediate family
							member and reflect a Rhode Island address)
						</li>
						<li>
							Payroll Check (must be in the name of the owner and reflect a Rhode
							Island address)
						</li>
					</ul>
					Valid within 30 days:
					<ul>
						<li>
							Letter from a Rhode Island Shelter or Halfway House (must be issued on
							letterhead, dated within the last 30 days, and contain contact
							information for the administrator)
						</li>
					</ul>
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Proof of Rhode Island Residency Document'],
		},
		UPLOAD_PROOF_OF_BUSINESS: {
			title: 'Proof of Business',
			description: (
				<p>
					Provide a screenshot printout of the Entity Summary from the RI SOS webpage. The
					address listed on the RI SOS site must match the address on the title
					application for the business. For more information, see the attached document.
				</p>
			),
			minUploads: 1,
			uploadBlockTitles: ['Entity Summary from RI SOS'],
		},
	},
};
