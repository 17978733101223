// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { CardBody } from '@ekhodealer/ekho-common/components';
import MobileViewContext from '../../../contexts/mobileViewContext';

const DefaultCardBody = forwardRef(({ children }, ref) => {
	const { mobileView } = useContext(MobileViewContext);
	return (
		<CardBody
			ref={ref}
			style={{
				scrollBehavior: 'smooth',
				maxHeight: '100%',
				overflowY: 'auto',
				paddingLeft: '3rem',
				paddingRight: '3rem',
				paddingTop: mobileView ? '1rem' : '3rem',
				paddingBottom: '3rem',
			}}>
			{children}
		</CardBody>
	);
});

DefaultCardBody.propTypes = {
	children: PropTypes.node.isRequired,
};

DefaultCardBody.defaultProps = {};

export default DefaultCardBody;
