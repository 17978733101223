import React, { Suspense } from 'react';
import ContentRoutes from './ContentRoutes';
import VehicleDetailsLoading from '../../pages/vehicle/loading/VehicleDetailsLoading';

const _loading = <VehicleDetailsLoading />;

const Content = () => {
	return (
		<main className='content'>
			<Suspense fallback={_loading}>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
