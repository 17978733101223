import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import { ThemeContextProvider } from '@ekhodealer/ekho-common/components';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import './i18n';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
	<Router>
		{/* <React.StrictMode> */}
		<ThemeContextProvider>
			<App />
		</ThemeContextProvider>
		{/* </React.StrictMode> */}
	</Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: )
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
