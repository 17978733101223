import { demoPages, dashboardMenu, layoutMenu } from '../menu';

const footers = [
	{ path: layoutMenu.blank.path, element: null, exact: true },
	// { path: layoutMenu.checkout.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: demoPages.userMgmt.path, element: null, exact: true },
	{ path: demoPages.reset.path, element: null, exact: true },
	{ path: demoPages.mfa.path, element: null, exact: true },
	{ path: demoPages.vehicleDetails.path, element: null, exact: true },
	{ path: dashboardMenu.vehicleSelect.path, element: null, exact: true },
	{ path: '*', element: null },
];

export default footers;
