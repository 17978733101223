import { dashboardMenu, demoPages, layoutMenu } from '../menu';

const headers = [
	{ path: layoutMenu.pageLayout.subMenu.onlySubheader.path, element: null, exact: true },
	{ path: layoutMenu.pageLayout.subMenu.onlyContent.path, element: null, exact: true },
	{ path: layoutMenu.blank.path, element: null, exact: true },
	// { path: layoutMenu.checkout.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.mfa.path, element: null, exact: true },
	{ path: demoPages.reset.path, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: demoPages.userMgmt.path, element: null, exact: true },
	{ path: demoPages.vehicleDetails.path, element: null, exact: true },

	// { path: dashboardMenu.kickoff.path, element: null, exact: true },
	// end
	// { path: dashboardMenu.home.path, element: <DashboardHeader />, exact: true },
	// { path: dashboardMenu.vehicleSelect.path, element: null, exact: true },
	{ path: dashboardMenu.vehicleSelect.path, element: null, exact: true },
	{
		path: `*`,
		element: null,
	},
];

export default headers;
