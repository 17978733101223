import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { ENV } from './common/data/constants';

// ----- get necessary auth and db objects ----- //
const oemKeyMatch = (app) => app.name === process.env.REACT_APP_OEM_KEY;
let app;
const fbApps = getApps();

// see if the specific app for that oem has already be instantiated - if so, use it - otherwise create it
const appIndex = fbApps.findIndex(oemKeyMatch);
if (appIndex === -1) {
	const appConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
	app = initializeApp(appConfig, process.env.REACT_APP_OEM_KEY);
} else {
	app = fbApps[appIndex];
}

// Set up AppCheck for production environment
if (process.env.REACT_APP_CURR_ENV !== ENV.PRODUCTION) {
	// eslint-disable-next-line no-restricted-globals
	self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_VERIFICATION_DEBUG_TOKEN;
}
// eslint-disable-next-line import/no-mutable-exports
let appCheck;
try {
	appCheck = initializeAppCheck(app, {
		provider: new ReCaptchaV3Provider(process.env.REACT_APP_SITE_KEY),
		isTokenAutoRefreshEnabled: true,
	});
} catch (error) {
	throw new Error(`AppCheck not initialized: ${error.message}`);
}

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// const googleProvider = new GoogleAuthProvider();
// const signInWithGoogle = async () => {
// 	try {
// 		const res = await signInWithPopup(auth, googleProvider);
// 		const { user } = res;
// 		const q = query(collection(db, 'customer'), where('uid', '==', user.uid));
// 		const docs = await getDocs(q);
// 		if (docs.docs.length === 0) {
// 			await addDoc(collection(db, 'customer'), {
// 				uid: user.uid,
// 				name: user.displayName,
// 				authProvider: 'google',
// 				email: user.email,
// 			});
// 		}
// 	} catch (err) {
// 		throw new Error(err);
// 	}
// };

export { db, auth, storage, appCheck };
