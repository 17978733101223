import React, { lazy } from 'react';
import { dashboardMenu, demoPages, layoutMenu } from '../menu';

const LANDING = {
	// DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	VEHICLE_SELECT: lazy(() => import('../pages/vehicle/VehicleSelect')),
	VEHICLE_DETAILS: lazy(() => import('../pages/vehicle/VehicleDetails')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
	LOGIN: lazy(() => import('../pages/login/login')), // login
	RESET: lazy(() => import('../pages/login/reset')),
	USERMGMT: lazy(() => import('../pages/presentation/auth/UserManagement')),
	MFA: lazy(() => import('../pages/login/mfa')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	// KICKOFF: lazy(() => import('../pages/presentation/page-layouts/KickoffTestingPage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const presentation = [
	/**
	 * Landing
	 */
	// {
	// 	path: dashboardMenu.home.path,
	// 	element: <LANDING.DASHBOARD />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.vehicleSelect.path,
		element: <LANDING.VEHICLE_SELECT />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.vehicleDetails.path,
	// 	element: <LANDING.VEHICLE_DETAILS />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.kickoff.path,
	// 	element: <PAGE_LAYOUTS.KICKOFF activeSectionKey='financing' />,
	// 	exact: true,
	// },
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
	},
	{
		path: demoPages.reset.path,
		element: <AUTH.RESET />,
	},
	{
		path: demoPages.userMgmt.path,
		element: <AUTH.USERMGMT />,
		exact: true,
	},
	{
		path: demoPages.mfa.path,
		element: <AUTH.MFA />,
		exact: true,
	},
	{
		path: demoPages.vehicleDetails.path,
		element: <LANDING.VEHICLE_DETAILS />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: layoutMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
