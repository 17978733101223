// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@ekhodealer/ekho-common/components';
import MobileViewContext from '../../../contexts/mobileViewContext';

const VehicleSectionSelectContainer = ({ children }) => {
	const { mobileView } = useContext(MobileViewContext);

	return (
		<Card
			shadown='none'
			style={{
				height: '100%',
				borderRadius: '0',
				marginBottom: '0',
				minHeight: 'min-content',
				padding: '0',
				// maxWidth: stacked ? '100%' : `${CHECKOUT_INFO_MAX_WIDTH}px`,
			}}>
			<CardBody
				style={{
					scrollBehavior: !mobileView ? 'smooth' : '',
					maxHeight: '100%',
					overflowY: 'auto',
					// minHeight: '880px',
					paddingLeft: '3rem',
					paddingRight: '3rem',
					paddingTop: '3rem',
				}}>
				<div>
					<div>{children}</div>
				</div>
			</CardBody>
		</Card>
	);
};

VehicleSectionSelectContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default VehicleSectionSelectContainer;
