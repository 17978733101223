import React from 'react';
import VehicleSectionSelectContainer from '../VehicleSectionSelectComponents/VehicleSectionSelectContainer';

const VehicleSectionSelectLoading = () => {
	return (
		<VehicleSectionSelectContainer loading>
			<h1 className='card-title ekho-placeholder-wave h1'>
				<span className='ekho-placeholder col-6' />
			</h1>
			<br />
			<br />
			<div className='p-0 ekho-placeholder-wave' style={{ height: '15vh' }}>
				<span className='ekho-placeholder col-12 h-100' />
			</div>
			<br />
			<br />
			<h2 className='card-title ekho-placeholder-wave'>
				<span className='ekho-placeholder col-12' />
			</h2>
			<br />
			<br />
			<div className='p-0 ekho-placeholder-wave' style={{ height: '10vh' }}>
				<span className='ekho-placeholder col-12 h-100' />
			</div>
			<br />
			<br />
			<h2 className='card-title ekho-placeholder-wave'>
				<span className='ekho-placeholder col-12' />
			</h2>
			<br />
			<br />
			<div className='p-0 ekho-placeholder-wave' style={{ height: '20vh' }}>
				<span className='ekho-placeholder col-12 h-100' />
			</div>
		</VehicleSectionSelectContainer>
	);
};

export default VehicleSectionSelectLoading;
