// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@ekhodealer/ekho-common/components';
import { TAR_PROCESS_ENUM } from '@ekhodealer/ekho-common';
import { determineTarProcess } from '@ekhodealer/ekho-common/utils';
import { getSectionDataMap } from '../../../common/data/constants';
import InfoCollectionProgressBar from '../../../components/vehicle/InfoCollectionProgressBar';
import MobileViewContext from '../../../contexts/mobileViewContext';
import CurrentVehicleContext from '../../../contexts/currentVehicleContext';

const VehicleInfoCollectionContainer = ({
	children,
	stepNum,
	activePageName,
	activeSectionKey,
	setMobileStage,
}) => {
	const { mobileView } = useContext(MobileViewContext);
	const { currentVehicle } = useContext(CurrentVehicleContext);

	const tarProcess = currentVehicle
		? determineTarProcess(currentVehicle)
		: TAR_PROCESS_ENUM.AUTOMATED;

	return (
		<Card
			shadow={mobileView ? 'none' : ''}
			style={{
				height: '100%',
				borderRadius: '0',
				marginBottom: '0',
				// maxWidth: stacked ? '100%' : `${CHECKOUT_INFO_MAX_WIDTH}px`,
				padding: '0',
				boxShadow: '-10px 0 20px -5px rgba(0, 0, 0, 0.08)',
			}}>
			<InfoCollectionProgressBar
				stepNum={stepNum}
				max={
					activeSectionKey ? getSectionDataMap(tarProcess, activeSectionKey).numSteps : 1
				}
				pageName={activePageName || ''}
				setMobileStage={setMobileStage}
			/>
			{children}
		</Card>
	);
};

VehicleInfoCollectionContainer.propTypes = {
	children: PropTypes.node.isRequired,
	stepNum: PropTypes.number,
	activePageName: PropTypes.string,
	activeSectionKey: PropTypes.string,
	setMobileStage: PropTypes.func.isRequired,
};

VehicleInfoCollectionContainer.defaultProps = {
	stepNum: 0,
	activePageName: '',
	activeSectionKey: '',
};

export default VehicleInfoCollectionContainer;
