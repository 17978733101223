import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { OemProfileContext } from '@ekhodealer/ekho-common/components';

const Brand = () => {
	const { oemProfileProps } = useContext(OemProfileContext);
	return (
		<div className='brand justify-content-center'>
			<div className='brand-logo'>
				<h1 className='brand-title '>
					<Link to='/home' aria-label='Logo' alt='generic OEM'>
						<img
							src={oemProfileProps.logos.lightSymbol}
							style={{ height: 'auto', width: 'auto', maxHeight: '25px' }}
							alt='small oem logo'
						/>
					</Link>
				</h1>
			</div>
			<div className='brand-small-logo'>
				<img
					src={oemProfileProps.logos.lightSymbol}
					style={{ height: 'auto', width: 'auto', maxHeight: '25px' }}
					alt='small oem logo'
				/>
			</div>
		</div>
	);
};

export default Brand;
