import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Progress, StandardHeader, OemProfileContext } from '@ekhodealer/ekho-common/components';
import { COLORS, COMPLETED_PAGE_SUFFIX } from '../../common/data/constants';
import MobileViewContext from '../../contexts/mobileViewContext';
import SupportModalContext from '../../contexts/supportModalContext';
// eslint-disable-next-line camelcase
import { track_contact_button_clicked } from '../../Utils/analyticsFuncs';

const InfoCollectionProgressBar = ({
	stepNum,
	max,
	pageName,
	setMobileStage,
	// hideHeaderBackButton,
}) => {
	const { toggleSupportModal } = useContext(SupportModalContext);
	const { oemProfileProps } = useContext(OemProfileContext);
	const { mobileView } = useContext(MobileViewContext);
	// If we're on a completed page, max out the progress bar
	if (pageName.endsWith(COMPLETED_PAGE_SUFFIX)) {
		stepNum = max;
	} else if (stepNum >= max) {
		// If we're not on a completed page but progress >= max, bring it down slightly
		const closeToMax = max * 0.95;
		stepNum = closeToMax;
	} else if (stepNum <= 0) {
		// If progress is at 0 or less, make it show some value
		const closeToZero = max * 0.05;
		stepNum = closeToZero;
	}

	useEffect(() => {
		if (mobileView) {
			window.intercomSettings.hide_default_launcher = true;
			window.Intercom('update', window.intercomSettings);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Progress
				value={stepNum}
				min={0}
				max={max}
				height='0.75vh'
				isStriped={false}
				isAnimated={false}
				isAutoColor={false}
				color={COLORS.INFO.name}
				className='thin-progress'
			/>
			{mobileView && (
				// <SubHeader
				// 	styles={{
				// 		paddingTop: '1rem',
				// 		paddingLeft: '3rem',
				// 		boxShadow: 'none',
				// 		backgroundColor: 'inherit',
				// 		borderBottom: '0',
				// 		margin: '0',
				// 	}}>
				// 	<SubHeaderLeft style={{ padding: '0' }}>
				// 		<Button icon='ChatBubble' color='info' isOutline onClick={openChat}>
				// 			Chat with us
				// 		</Button> // trigger
				// 	</SubHeaderLeft>
				// </SubHeader>
				<div style={{ padding: '2rem 3rem 1.5rem 3rem' }}>
					<StandardHeader
						hasBackButton
						backButtonText='To Home'
						backButtonOnClick={() => {
							setMobileStage('select');
						}}
						logoUrl=''
						supportButtonOnClick={() => {
							track_contact_button_clicked(oemProfileProps);
							toggleSupportModal(true);
						}}
					/>
				</div>
			)}
		</>
	);
};

InfoCollectionProgressBar.propTypes = {
	setMobileStage: PropTypes.func.isRequired,
	stepNum: PropTypes.number,
	max: PropTypes.number,
	pageName: PropTypes.string,
	// hideHeaderBackButton: PropTypes.bool,
};

InfoCollectionProgressBar.defaultProps = {
	// hideHeaderBackButton: false,
	stepNum: 0,
	max: 1,
	pageName: '',
};

export default InfoCollectionProgressBar;
